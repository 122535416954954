
export function scrollToElement($el) {
  var elTop = $el.offset().top;
  // if ($('body').hasClass('s_sticky')) {
    // elTop += $('.localbar').outerHeight();
    elTop -= $('.header').outerHeight() + 40;
  // }
  $('html, body').animate({scrollTop: elTop},'slow');
}

export function scrollToAnchor(anchorId) {
  var $anchorElement = $('[id="' + anchorId + '"], [name="' + anchorId + '"], [data-id="' + anchorId + '"]');
  if ($anchorElement.length) {
    scrollToElement($anchorElement);
  }
}