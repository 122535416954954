<template>
<div>

    <div class="form-response" :class="{ 's_active' : isSuccess }">
      <div class="form-response-aside">
        <svg class="icon"><use xlink:href="#check-circle"></use></svg>
      </div>
      <div class="form-response-main" v-html="successMessage"></div>
    </div>

  <form method="post" @submit="formSubmit" :id="`form_${formHandle}`" class="form-freeform" :class="{ 's_loading' : isSubmitted }" novalidate="true" v-if="!isSuccess">

    <input type="hidden" name="action" value="freeform/api/form">
    <input type="hidden" name="formHash" :value="formHash">
    <input type="hidden" name="CRAFT_CSRF_TOKEN" :value="formVars.csrf">

    <!-- honeypot -->
    <div aria-hidden="true" tabindex="-1" style="position: absolute !important; width: 0px !important; height: 0px !important; overflow: hidden !important;">
      <label aria-hidden="true" tabindex="-1" for="freeform_form_handle">
        Leave this field blank
      </label>
      <input
        type="text"
        :value="formHoneypot.hash"
        :name="formHoneypot.name"
        :id="formHoneypot.name"
        aria-hidden="true"
        tabindex="-1">
    </div>

    <div :class="row.rowClass" v-for="row in formLayout">
      <div v-for="field in row.columns" :class="[field.groupClass, {'has-error' : $v.formFields[field.handle].$dirty && $v.formFields[field.handle].$error}]" v-bind:key="field.id">

        <template v-if="field.type == 'submit'">
          <div :class="field.controlClass">
            <input
              type="submit"
              name="submit"
              class="btn"
              :class="field.inputClass"
              :value="field.label" />
          </div>
        </template>

        <template v-else-if="field.type == 'checkbox'">
          <div :class="field.controlClass">

            <label :class="field.labelClass">
              <input
                type="checkbox"
                :name="field.handle"
                :id="field.handle"
                :value="field.value"
                :required="field.required ? true : false"
                :class="field.inputClass"
                false-value=""
                v-model="formFields[field.handle]"
                @change="$v.formFields[field.handle].$touch()" />
              <span class="checkbox-value">{{ field.label }}</span>
            </label>

            <template v-if="$v.formFields[field.handle]">
              <p v-if="$v.formFields[field.handle].$dirty && $v.formFields[field.handle].$error" class="has-error">
                <span v-for="param in $v.formFields[field.handle].$params">
                  <span v-if="!$v.formFields[field.handle][param.type]">{{ errorMessages[param.type] }}</span>
                </span>
              </p>
            </template>

          </div>
        </template>

        <template v-else>

          <label :for="field.handle">
            {{ field.label }}
            <span class="help-block" v-if="field.instructions && !field.groupClass.includes('l_placeholder')" v-html="field.instructions"></span>
          </label>

          <div :class="field.controlClass">

            <template v-if="field.computedType == 'option_group'">
              <label
                v-for="opt in field.options"
                :class="`${field.optionType}-${field.optionClass}`">
                <input
                  :type="field.optionType"
                  :name="field.name"
                  :value="opt.value"
                  :class="field.inputClass"
                  v-model="formFields[field.handle]"
                  @change="$v.formFields[field.handle].$touch()"
                />
                <span :class="`${field.optionType}-value`" v-html="opt.label"></span>
              </label>
            </template>

            <template v-else-if="field.type == 'select'">
              <select
                :name="field.handle"
                :id="field.handle"
                :required="field.required ? true : false"
                :class="field.inputClass"
                v-model="formFields[field.handle]"
                @input="$v.formFields[field.handle].$touch()">
                <option v-for="opt in field.options" :value="opt.value">{{ opt.label }}</option>
              </select>
            </template>

            <template v-else-if="field.type == 'textarea'">
              <textarea
                :name="field.handle"
                :id="field.handle"
                :required="field.required ? true : false"
                :class="field.inputClass"
                :rows="field.inputAttributes.rows"
                :data-min-rows="field.inputAttributes.dataMinRows"
                v-model="formFields[field.handle]"
                @input="$v.formFields[field.handle].$touch()">
              </textarea>
            </template>

            <template v-else>
              <input
                :type="field.type"
                :name="field.handle"
                :id="field.handle"
                :class="field.inputClass"
                value=""
                v-model="formFields[field.handle]"
                :placeholder="field.placeholder"
                :autocapitalize="field.inputAttributes.autocapitalize"
                :autocorrect="field.inputAttributes.autocorrect"
                :required="field.required ? true : false"
                @input="$v.formFields[field.handle].$touch()">
            </template>

            <template v-if="$v.formFields[field.handle]">
              <p v-if="$v.formFields[field.handle].$dirty && $v.formFields[field.handle].$error" class="has-error">
                <span v-for="param in $v.formFields[field.handle].$params">
                  <span v-if="!$v.formFields[field.handle][param.type]">
                    <span v-if="param.type == 'minLength'">
                      {{ errorMessages[param.type].replace('0', param.min) }}
                    </span>
                    <span v-else-if="param.type == 'maxLength'">
                      {{ errorMessages[param.type].replace('0', param.max) }}
                    </span>
                    <span v-else>{{ errorMessages[param.type] }}</span>
                  </span>
                </span>
              </p>
            </template>

            <div class="help-block" v-if="field.instructions && field.groupClass.includes('l_placeholder')" v-html="field.instructions"></div>

            <!-- TODO: dynamic recipients block -->

            <!-- TODO: captcha -->

          </div>

        </template>

      </div>
    </div>

  </form>
</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators';
// import { page } from 'vue-analytics';
// import jQuery from 'jquery';
import * as scrll from '../funcs.js';
export default {
  name: "Freeform",
  data() {
    return {
      formLayout: [],
      formFields: {},
      formHoneypot: [],
      formTouched: false,
      validate: {
        'formFields': {}
      },
      errors: false,
      errorMessages: {
        'required': 'This field is required',
        'email': 'Please enter a valid email address',
        'minLength': 'Must be more than 0 characters',
        'maxLength': 'Must be less than 0 characters'
      },
      isSubmitted: false,
      isSuccess: false,
      successMessage: '<h4>Success</h4><p>The form was submitted successfully.</p>'
    };
  },
  props: {
    formHandle: String,
    formHash: String,
    formReturnUrl: String,
    formJson: Object,
    formVars: Object
  },
  mounted() {

    // console.log(this.formJson);

    // init
    this.successMessage = this.formVars.successMessage;
    this.formHoneypot = this.formVars.honeypot;

    // custom row class
    var rowClass = 'form-row';
    if (typeof this.formJson.customAttributes !== 'undefined') {
      this.formJson.customAttributes.forEach( (attr) => {

        // custom classes
        if (attr.attribute == 'rowClass') {
          rowClass = attr.value;
        }

      });
    }

    // loop through form layout
    this.formJson.composer.layout[0].forEach( (row, index) => {

      var defaultCols = 12  / row.columns.length;
      var columns = [];

      row.columns.forEach( (col, index) => {

        var field = this.formJson.composer.properties[col];
        // console.log(field);

        // init
        field.cols = defaultCols;
        field.customClass = '';
        field.isCheckbox = false;

        // required
        if (typeof field.required == 'undefined') {
          field.required = false;
        }

        if (typeof field.inputAttributes !== 'undefined') {
          field.inputAttributes.forEach( (attr) => {

            // custom classes
            if (attr.attribute == 'class') {
              field.customClass += ' ' + attr.value;
            }

            // custom cols
            if (attr.attribute == 'col') {
              field.cols += attr.value;
            }

          });
        }

        // column class
        field.groupClass = 'form-group';
        field.groupClass += ' col-' + field.cols;
        field.groupClass += field.customClass;

        // required
        if (field.required) {
          field.groupClass += ' l_required';
        }
        // groupClass =+ ' ' + form.customAttributes.columnClass

        // control class
        field.controlClass = 'form-control';

        // input class
        field.inputClass = '';
        if (typeof field.inputAttributes == 'undefined') {
          field.inputAttributes = {};
        }

        // label class
        field.labelClass = '';

        // textarea auto expand
        if (field.type == 'textarea') {
          field.inputClass += ' l_auto-expand';
          field.inputAttributes = {
            'rows': 1,
            'dataMinRows': 1
          }
        }

        // computed type
        field.computedType = '';
        if (field.type == 'checkbox_group' || field.type == 'radio_group') {
          field.computedType = 'option_group';
        }


        // single checkbox
        if (field.type == 'checkbox') {
          field.labelClass += 'checkbox-stacked';
          if (field.required) {
            field.labelClass += ' l_required';
          }
        }

        // checkbox and radio option type
        field.optionType = '';
        field.name = field.handle;
        if (field.type.includes('checkbox')) {
          field.optionType = 'checkbox';
          if (field.type.includes('group')) {
            field.name = field.handle + '[]';
          }
        } else if (field.type.includes('radio')) {
          field.optionType = 'radio';
        }

        // checkbox and radio - stacked or inline
        field.optionClass = 'stacked'
        if (typeof field.oneLine !== 'undefined' && field.oneLine) {
          field.optionClass = 'inline';
        }

        // placeholders
        if (((typeof field.placeholder !== 'undefined' && field.placeholder.length == 0) || typeof field.placeholder == 'undefined') && (field.type == 'text' || field.type == 'textarea' || field.type == 'number' || field.type == 'email' || field.type == 'select')) {
          field.groupClass += ' l_placeholder';
        }

        // html5 fieldtypes
        if (typeof field.handle !== 'undefined') {
          var fieldNameLower = field.handle.toLowerCase();
          if (fieldNameLower.includes('phone') || fieldNameLower.includes('mobile') || fieldNameLower.includes('fax')) {
            field.type = 'tel';
          }
          if (fieldNameLower.includes('email')) {
            field.type = 'email';
            field.inputAttributes['autocapitalize'] = 'off';
            field.inputAttributes['autocorrect'] = 'off';
          }
          if (fieldNameLower.includes('address')) {
            field.inputAttributes['autocorrect'] = 'off';
          }
          if (fieldNameLower.includes('name')) {
            field.inputAttributes['autocorrect'] = 'off';
          }
          if (fieldNameLower.includes('postcode')) {
            field.type = 'number';
          }
        }

        // set default model
        if (field.type == 'checkbox_group') {
          Vue.set(this.formFields, [field.handle], []);
          if (typeof field.values !== 'undefined' && field.values.length) {
            Vue.set(this.formFields, [field.handle], field.values);
          }
        } else {
          Vue.set(this.formFields, [field.handle], '');
          if (typeof field.value !== 'undefined' && field.value.length) {
            Vue.set(this.formFields, [field.handle], field.value);
          }
        }

        columns.push(field);

      });

      this.formLayout.push({
        id: row.id,
        columns: columns,
        cols: row.columns.length,
        rowClass: rowClass
      });

    });

  },
  methods: {

    formSubmit(event) {

      this.isSubmitted = true;

      event.preventDefault();

      this.$v.$touch();

      this.formTouched = !this.$v.formFields.$anyDirty;
      this.errors = this.$v.formFields.$anyError;
      if (this.errors === false && this.formTouched === false) {

        // console.log('form submitted');

        var form = event.target;
        var data = new FormData(form);

        var method = form.getAttribute("method");
        var action = form.getAttribute("action");

        // console.log( data);

        axios({
          url: action ? action : window.location.href,
          method: method ? method : "post",
          data: data,
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            HTTP_X_REQUESTED_WITH: "XMLHttpRequest",
          },
        })
        .then((responseObject) => {
          var response = responseObject.data;

          if (response.success && response.finished) {
            this.isSuccess = true;
            // form.reset();
            console.log("Form posted successfully");
            console.log(response.success);
            console.log(response);
          } else if (response.errors || response.formErrors) {
            this.isSubmitted = false;
            this.isSuccess = false;
            console.log("Form failed to submit");
            console.log("Field Errors", response.errors);
            console.log("Form Errors", response.formErrors);
          }

          // scroll to top
          scrll.scrollToElement(jQuery('#form_' + this.formHandle));

          // virtual pageview for return URL for google analytics
          if (this.formReturnUrl.length) {
            page(this.formReturnUrl);
          }

          // update the Honeypot field if using JS enhancement
          if (response.honeypot) {
            this.formHoneypot = response.honeypot;
          }
        })
        .catch((error) => {
          console.error(error);
          this.isSubmitted = false;
          this.isSuccess = false;

        });

      } else {
        this.isSubmitted = false;
        this.isSuccess = false;

        // scroll to top
        setTimeout(function(){
          scrll.scrollToElement(jQuery('.form-group.has-error'));
        }, 10);

      }

    }

  },
  validations: function() {
    var v = {};
    v['formFields'] = {};

    this.formJson.composer.layout[0].forEach( (row, index) => {

      var defaultCols = 12  / row.columns.length;
      var columns = [];

      row.columns.forEach( (col, index) => {

        var field = this.formJson.composer.properties[col];

        v.formFields[field.handle] = {};

        // required
        if (typeof field.required !== 'undefined' && field.required) {
          v.formFields[field.handle]['required'] = required;
        }

        // email
        if (field.type == 'email') {
          v.formFields[field.handle]['email'] = email;
        }

        // max length
        if (typeof field.maxLength !== 'undefined') {
          v.formFields[field.handle]['maxLength'] = maxLength(field.maxLength);
        }

        // attributes loop
        if (typeof field.inputAttributes !== 'undefined' && field.inputAttributes.length) {
          field.inputAttributes.forEach( (attr) => {

            // min length
            if (attr.attribute == 'minLength') {
              v.formFields[field.handle]['minLength'] = minLength(attr.value);
            }

            // max length
            if (attr.attribute == 'maxLength') {
              v.formFields[field.handle]['maxLength'] = maxLength(attr.value);
            }

          });
        }

        // TODO: more validations?
        // TODO: dynamic validation variable

      });

    });

    return v;
  },
};
</script>

<style scoped>
</style>

<!--
EXAMPLE:
{% set formHandle = 'contact' %}
{% set form = craft.freeform.form(formHandle) %}
<Freeform
  form-handle="{{ form.handle }}"
  form-hash="{{ form.getHash() }}"
  form-return-url="{{ form.returnUrl }}"
  :form-json="{{ craft.vueFreeformModule.renderFormJson(formHandle) }}"
  :form-vars="{{ craft.vueFreeformModule.renderFormVars(formHandle) }}">
</Freeform>
 -->