// add regex to validator
$.validator.addMethod(
  "regex",
  function(value, element, regexp) {
      var re = new RegExp(regexp);
      return this.optional(element) || re.test(value);
  },
  "Please check your input."
);

// add filesize method
$.validator.addMethod('filesize', function(value, element, param) {
    // param = size (in bytes)
    // element = element to validate (<input>)
    // value = value of the element (file name)
    return this.optional(element) || (element.files[0].size <= param)
});

// Accept a value from a file input based on a required mimetype
$.validator.addMethod("accept", function(value, element, param) {
  // Split mime on commas in case we have multiple types we can accept
  var typeParam = typeof param === "string" ? param.replace(/\s/g, "").replace(/,/g, "|") : "image/*",
  optionalValue = this.optional(element),
  i, file;

  // Element is optional
  if (optionalValue) {
    return optionalValue;
  }

  if ($(element).attr("type") === "file") {
    // If we are using a wildcard, make it regex friendly
    typeParam = typeParam.replace(/\*/g, ".*");

    // Check if the element has a FileList before checking each file
    if (element.files && element.files.length) {
      for (i = 0; i < element.files.length; i++) {
        file = element.files[i];

        // Grab the mimetype from the loaded file, verify it matches
        if (!file.type.match(new RegExp( "\\.?(" + typeParam + ")$", "i"))) {
          return false;
        }
      }
    }
  }

  // Either return true because we've validated each file, or because the
  // browser does not support element.files and the FileList feature
  return true;
}, $.validator.format("Please enter a value with a valid mimetype."));

// Older "accept" file extension method. Old docs: http://docs.jquery.com/Plugins/Validation/Methods/accept
$.validator.addMethod("extension", function(value, element, param) {
  param = typeof param === "string" ? param.replace(/,/g, '|') : "png|jpe?g|gif";
  return this.optional(element) || value.match(new RegExp(".(" + param + ")$", "i"));
}, jQuery.validator.format("Please enter a value with a valid extension."));

// date format
$.validator.addMethod(
    "australianDate",
    function(value, element) {
        // put your own logic here, this is just a (crappy) example
        return value.match(/^\d\d?\/\d\d?\/\d\d\d\d$/);
    },
    "Please enter a date in the format dd/mm/yyyy."
);