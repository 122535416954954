
//* example form validation =================================================== */

$(document).ready(function(){
  $('.form-example').validate({
    onfocusout: function (element) {
      $(element).attr('data-edit', 1).valid();
    },
    onkeyup: function(element) {
      if ($(element).attr('data-edit') == '1') {
        $(element).valid();
      }
    },
    onchange: function(element) {
      if ($(element).attr('data-edit') == '1') {
        $(element).valid();
      }
    },
    validClass: 'has-success',
    errorElement: 'p',
    errorClass: 'has-error',
    errorPlacement: function(error,element) {
      error.insertAfter(element.closest('.form-control'));
    },
    focusInvalid: false,
    invalidHandler: function(form, validator) {
      if (!validator.numberOfInvalids())
          return;
      $('html, body').animate({
        scrollTop: $(validator.errorList[0].element).offset().top-50
      }, 500);
    },
    rules: {
      name: 'required',
      email: {
        required: true,
        email: true
      },
      phone: {
        required: true,
        regex: /^(\(?(\+61|[0-9]) ?[0-9]\)?)? ?[0-9]{2} ?[0-9]{2} ?[0-9]{1} ?[0-9]{3}$/
      },
      checkboxes_inline: 'required',
      checkboxes_stacked: 'required',
      'file_upload[0]': {
        extension: 'pdf|doc|docx|gif|png|jpg|jpeg',
        filesize: 5242880
      }
    },
    messages: {
      phone: {
        regex: 'Please enter a valid phone number.'
      },
      email: {
        email: 'Please enter a valid email address.'
      },
      'file_upload[0]': {
        extension: 'Please select a valid file type',
        filesize: 'File must be less than 5MB'
      }
    }
  });
});


//* example freeform setup & validation =================================================== */

$(document).ready(function(){
  if ($('.form-freeform').length) {

    // convert to html5 fieldtypes
    $('[name="phone"],[name="mobile"]').attr('type','tel');
    $('[name="email"]').attr('type','email').attr('autocapitalize','off').attr('autocorrect','off');
    $('[name="address"]').attr('autocorrect','off');
    $('[name="name"]').attr('autocorrect','off');
    $('[name="postcode"]').attr('type','number');

    // validate default
    $('.form-freeform').validate({
      ignore: ".ignore",
      validClass: 'has-success',
      errorElement: 'p',
      errorClass: 'has-error',
      errorPlacement: function(error,element) {
        validateErrorPlacement(error,element,'under'); // 'under' or 'label'
      },
      rules: {
      },
    });

    $('.form-freeform .l_required').find('input, select, textarea').each(function() {
      if ($(this).attr('type') == 'email') {
        $(this).rules('add', {
          required: true,
          email: true,
          messages: {
            // required: 'This is a required field',
            email: 'Please enter a valid email address'
          }
        });
      } else {
        $(this).rules('add', {
          required: true,
          messages: {
            // required: 'This is a required field'
          }
        });
      }
    });

    $('.form-freeform [type="hidden"]').each(function() {
      if ($(this).hasClass('hiddenRecaptcha')) {
      // console.log('testing');
        $(this).rules('add', {
          required: true
        });
      }
    });

  }
});


//* example form ajax submit =================================================== */

$(document).on('submit','.form-ajax',function(e){

  e.preventDefault();

  // init
  var $thisForm = $(this);
  var thisUrl = $thisForm.attr('action');
  var thisFormId = $thisForm.attr('id');
  var success_msg = $thisForm.attr('data-msg');
  if (success_msg.length == 0) {
    success_msg = 'Thanks for getting in touch.';
  }

  // generate form data
  var form_data = new FormData(document.getElementById(thisFormId));
  form_data.append($(this).attr('name'), $(this).val());

  // validate form and go
  $thisForm.validate();
  if ($thisForm.valid()) {
    $thisForm.find('input, select, textarea').attr('disabled', 'disabled');
    $thisForm.addClass('s_loading');
    $.ajax({
      url: thisUrl,
      type: "post",
      data: form_data,
      cache: false,
      contentType: false,
      processData: false,
      error: function(response, textStatus, errorThrown){

        console.log(response);
        console.log(textStatus);
        console.log(errorThrown);
        $thisForm.find('input, select, textarea').removeAttr('disabled');
        $thisForm.removeClass('s_loading');

        if (response['responseText'].length) {
          responseText = $.parseJSON(response['responseText']);
          alert(responseText['error']);
        } else {
          alert('Sorry, there was an error submitting your form. Please try again, or call if issues persist.');
        }

      },
      success: function(response, textStatus, jqXHR) {

        $thisForm.find('input, select, textarea').removeAttr('disabled');
        $thisForm.removeClass('s_loading');

        if (response.success && response.finished) {

          $thisForm.html('<div class="form-group"><p><strong>Form successfully submitted</strong></p><p>' + success_msg + '</p></div>');

        } else if (response.errors) {

          for (var key in response.errors) {
            if (!response.errors.hasOwnProperty(key)) continue;
            var messages = response.errors[key];
            var $errors  = $("<p>", {"class": "has-error"});
            for (var i = 0; i < messages.length; i++) {
              var $li = $("<span>");
              $li.html(messages[i]);
              $errors.append($li);
            }

            var $input = $("*[name=" + key + "], *[name='" + key + "[]']");

            $formGroup = $input.parents(".form-group");
            $formGroup.addClass("has-error");

            $formGroup.append($errors);
          }

        }

      }
    });
  };

  return false;
});





//* contact form validation =================================================== */

$(document).ready(function(){
  if (window.location.href.indexOf('contact') !== -1) {
    $('form').validate({
      onfocusout: function (element) {
        $(element).attr('data-edit', 1).valid();
      },
      onkeyup: function(element) {
        if ($(element).attr('data-edit') == '1') {
          $(element).valid();
        }
      },
      validClass: 'has-success',
      errorElement: 'p',
      errorClass: 'has-error',
      errorPlacement: function(error,element) {
        validateErrorPlacement(error,element,'under'); // 'under' or 'label'
      },
      rules: {
        name: "required",
        email: {
          required: true,
          email: true
        },
        user_message: "required"
      }
    });
  }
});


//* subscribe form validation =================================================== */

$(document).ready(function(){
  $('#form_subscribe').validate({
    onfocusout: function (element) {
      $(element).attr('data-edit', 1).valid();
    },
    onkeyup: function(element) {
      if ($(element).attr('data-edit') == '1') {
        $(element).valid();
      }
    },
    validClass: 'has-success',
    errorElement: 'p',
    errorClass: 'has-error',
    errorPlacement: function(error,element) {
      validateErrorPlacement(error,element,'under'); // 'under' or 'label'
    },
    rules: {
      name: "required",
      email: {
        required: true,
        email: true
      }
    }
  });
});



//* file upload fields =================================================== */

function inputFileInit($item) {

  // init
  $input = $item;
  $label = $input.next( 'label' );
  label_val = $label.html();

  // on change input
  $input.on('change', function(e) {

    // get filename(s)
    var fileName = '';
    if (this.files && this.files.length > 1) {
      fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
    } else if( e.target.value ) {
      fileName = e.target.value.split( '\\' ).pop();
    }

    // set filename on label
    if (fileName) {
      $label.html( fileName );
    } else {
      $label.html( labelVal );
    }

  });

  // firefox bug fix
  $input.on('focus', function() {
    $input.addClass('s_focus');
  });
  $input.on('blur', function() {
    $input.removeClass('s_focus');
  });

}

function inputFileInitAll() {
  $('.file-upload').each(function() {
    inputFileInit($(this));
  });
}

$(document).ready(function(){
  inputFileInitAll();
});