import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', () => {

  let mm = gsap.matchMedia();

  // Scrolling parallax effect for about us image
  document.querySelectorAll('.js-about-img').forEach(el => {
    mm.add("(min-width: 769px)", () => {
      gsap.set(el, { y: -50, scale: 1 });
      gsap.to(el, {
        y: 50,
        ease: 'none',
        scrollTrigger: {
          trigger: el,
          scrub: 1,
        }
      });
    });
  });

  // Scrolling parallax effect for about about us heart icon
  document.querySelectorAll('.js-icon-heart').forEach(el => {
    mm.add("(min-width: 769px)", () => {
      gsap.set(el, { y: -75, scale: 1 });
      gsap.to(el, {
        y: 75,
        ease: 'none',
        scrollTrigger: {
          trigger: el,
          scrub: 1,
        }
      });
    });
  });

});
