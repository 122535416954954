
import Vue from 'vue';
import objectFitImages from 'object-fit-images';
// import objectFitPolyfill from 'objectFitPolyfill';
import picturefill from 'picturefill';
import Components from "./components";
import * as scrll from './funcs.js';

import Swiper from 'swiper';
import { Navigation, Pagination, Controller } from 'swiper/modules';
// import SwiperCore, { Pagination, Lazy } from 'swiper/core';
// SwiperCore.use([Pagination, Lazy]);

import 'swiper/swiper-bundle.css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

import './parallax';

new Vue({
  el: "#app",
  delimiters: ["${", "}"]
});

//* objectfit polyfill =================================================== */
objectFitImages();
picturefill();
// objectFitPolyfill();

//* is touch device =================================================== */

var isTouch = false;
if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
  isTouch = true;
}


//* breakpoints =================================================== */

function breakpointWidth(bp) {
  bpWidth = 768;
  if (bp == 'baby') {
    bpWidth = 479;
  }
  if (bp == 'teen') {
    bpWidth = 600;
  }
  if (bp == 'mama') {
    bpWidth = 768;
  }
  if (bp == 'mega') {
    bpWidth = 1279;
  }
  return bpWidth;
}

function breakpointMax(bp) {
  bpWidth = breakpointWidth(bp);
  if ($(window).width() <= bpWidth) {
    return true;
  } else {
    return false;
  }
  return true;
}

function breakpointMin(bp) {
  bpWidth = breakpointWidth(bp);
  if ($(window).width() > bpWidth) {
    return true;
  } else {
    return false;
  }
  return true;
}


//* scroll to anchor =================================================== */

// all hashtags
$(document).on('click','a[href^="#"]',function(){
  var $this = $(this);
  var aId = $this.attr('href').replace('#','');;
  var $anchorElement = $('[id="' + aId + '"], [name="' + aId + '"], [data-id="' + aId + '"]');
  if ($anchorElement.length > 0) {
    scrll.scrollToAnchor(aId);
    return false;
  }
});

// scroll if hashtag
$(document).ready(function(){
  var hash = window.location.hash.replace('#', '');
  if (hash != '') {
    scrll.scrollToAnchor(hash);
  }
});

var bodyEl = document.getElementsByTagName('body');

if (document.getElementById('home-logo')) {
  
  window.addEventListener('scroll', function(e) {
    var logo = document.getElementById('home-logo');
    var menu = document.getElementById('header-menu');
    
    menu.classList.add('s_scrolled');
    
    if (window.scrollY > ( (logo.offsetTop - menu.offsetHeight) + logo.offsetHeight)) {
      menu.classList.add('s_logo-scrolled');
    }
    
    if (window.scrollY == 0 ){
      menu.classList.remove('s_logo-scrolled');
    }
  });
  
} else if ( bodyEl[0].classList.contains('l_page') ){
  
  window.addEventListener('scroll', function(e) {
    var menu = document.getElementById('header-menu');
    var menuRowEl = document.getElementsByClassName('menu-row');

    // var rows = document.getElementsByClassName('row');
    
    if (window.scrollY > menuRowEl[0].offsetTop) {
      menu.classList.add('s_logo-scrolled');
      menu.classList.add('s_scrolled');      

      // rows[2].setAttribute("style", "padding-top:150px;");
    }
    
    // if (window.scrollY == 0 ){
    //   menu.classList.remove('s_logo-scrolled');
    // }
  });

}

document.addEventListener('DOMContentLoaded', () => {
  const swiper = new Swiper('.support-swiper', {
    preloadImages: false,
    lazy: true,
    pagination: {
      type: 'bullets',
      clickable: true,
      el: '.swiper-pagination',
    },
    slidesPerView: 1,
    spaceBetween: 15,
    breakpoints: {
      769: {
        slidesPerView: 2,
        spaceBetween: 30
      }
    },
    modules: [Navigation, Pagination]
  });

  const newsLinkSwiper = new Swiper('.js-news-link-swiper', {
    loop: true,
    allowTouchMove: false
  });

  const newsTitleSwiper = new Swiper('.js-news-title-swiper', {
    loop: true,
    allowTouchMove: false,
    autoHeight: true
  });

  const newsControlledSwipers = [newsLinkSwiper, newsTitleSwiper ];

  const newsBgSwiper = new Swiper('.js-news-bg-swiper', {
    preloadImages: true,
    // lazy: {
    //   loadPrevNext: true,
    // },
    loop: true,
    // watchSlidesProgress: true,
    navigation: {
      nextEl: '.js-news-swiper-next',
      prevEl: '.js-news-swiper-prev',
    },
    controller: {
      control: newsControlledSwipers
    },
    modules: [Navigation, Controller]
  });
});

// Video
document.addEventListener('DOMContentLoaded', () => {

  // Get video
  const headerVid = document.querySelector('#header-video');

  if (headerVid) {
    // define functions
    function pauseVideo(video, button) {
      button.classList.add('paused');
      button.classList.remove('playing');
      video.pause();
    }

    function playVideo(video, button) {
      button.classList.add('playing');
      button.classList.remove('paused');
      video.play();
    }

    // get btn
    var videoBtn = document.querySelector('.js-playpause-header-vid');

    if (videoBtn) {
      videoBtn.addEventListener('click', function () {
        console.log('clicked');
        if (videoBtn.classList.contains('playing')) {
          console.log('pausing...');
          pauseVideo(headerVid, videoBtn);
        }
        else {
          console.log('playing...');
          playVideo(headerVid, videoBtn);
        }
      })
    }


    // reduced motion stuff
    const isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`) === true || window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

    if (!!isReduced) {
      pauseVideo(headerVid, videoBtn);
    }
  }

});